"use client";
import React, { FC } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { IFundraisingSettings } from "@/composables/fundraising-settings.type";
import { Card } from "../ui/card";
import { createInternalPath } from "@/utils";
import { useAppSelector } from "@/lib/redux/hook";

interface FundraisingMenuProps {
  fundraisingSettings: IFundraisingSettings;
}

const FundraisingMenu: FC<FundraisingMenuProps> = ({ fundraisingSettings }) => {
  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const pathname = usePathname();
  const {
    isZakatVisible = false,
    isWaqafVisible = false,
    isQurbanVisible = false,
  } = fundraisingSettings ?? {};
  const exactRoute = pathname?.split("campaign/")[1];

  const fundraisingsType = [
    { name: "Donasi", icon: "fa:heart", link: "donation" },
    ...(isZakatVisible
      ? [{ name: "Zakat", icon: "fa6-solid:divide", link: "zakat-calculator" }]
      : []),
    ...(isWaqafVisible
      ? [{ name: "Wakaf", icon: "fa:home", link: "waqaf" }]
      : []),
    ...(isQurbanVisible
      ? [{ name: "Qurban", icon: "fa6-solid:box", link: "qurban" }]
      : []),
  ];

  if (!isZakatVisible && !isWaqafVisible && !isQurbanVisible) {
    return null;
  }

  return (
    <div className="mt-5">
      <Card
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
        }}
        className="py-2.5 flex"
      >
        {fundraisingsType.map(({ name, icon, link }, index) => {
          return (
            <Link
              href={createInternalPath(
                `/galang-dana/v2/campaign/${link}`,
                user.paymeLink
              )}
              key={index}
              className="flex-1"
            >
              <div
                className={`text-center flex flex-col items-center pt-1 ${
                  link === exactRoute ? "text-blue-500" : "text-gray-500"
                }  ${index >= fundraisingsType.length - 1 ? "" : "border-r"} hover:text-blue-500`}
              >
                <Icon icon={icon} />
                <h4 className="text-base font-semibold mt-[5px] text-inherit">
                  {name === "Donasi" ? t("word.donation") : name}
                </h4>
              </div>
            </Link>
          );
        })}
      </Card>
    </div>
  );
};

export default FundraisingMenu;
