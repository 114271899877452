"use client";
import React, { FC } from "react";
import { get } from "lodash";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { GQL_GET_TOTAL_FUNDRAISING_BY_PAYMENT_LINK_ID } from "../../gqls";
import { formatRp, REGEX_UUID } from "../../utils";
import LoadingAnimation from "../loading-animation";
import { Icon } from "@iconify/react";
import { Progress } from "../ui/progress";
import { Tooltip, TooltipProvider } from "../ui/tooltip";
import { TooltipContent, TooltipTrigger } from "@radix-ui/react-tooltip";
import { cn } from "@/lib/utils";

interface DonationReceivedProps {
  paymentLinkId: string;
  isTargetVisible: boolean;
  isV2?: boolean;
  isCardV2?: boolean;
}

export const DonationReceived: FC<DonationReceivedProps> = ({
  paymentLinkId,
  isTargetVisible,
  isV2 = false,
  isCardV2 = false,
}) => {
  const { t } = useTranslation();
  const gparam = {
    pageSize: 1,
    page: 1,
    ...(REGEX_UUID.test(paymentLinkId)
      ? { id: paymentLinkId }
      : { link: paymentLinkId }),
  };

  const { loading, error, data } = useQuery(
    GQL_GET_TOTAL_FUNDRAISING_BY_PAYMENT_LINK_ID,
    {
      context: { public: true },
      variables: gparam,
    }
  );

  const totalFundraising = get(
    data,
    "getTotalFundraisingsByPaymentLinkID.totalFundraising"
  );
  const targetFundraising = get(
    data,
    "getTotalFundraisingsByPaymentLinkID.target"
  );

  if (error) return `Error! ${error.message}`;
  if (loading)
    return (
      <div className="mb-2">
        <LoadingAnimation twodashline />
      </div>
    );

  return (
    <div className="font-open-sans">
      {isV2 ? (
        <>
          <div className="flex justify-between mb-3 text-[13px]">
            <div className="w-1/2">
              <p>
                <span className="text-gray-600">{t("word.collected")}:</span>{" "}
                <br />
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span className="text-blue-500 flex gap-1 items-center font-semibold">
                        {formatRp(totalFundraising || 0)}
                        <Icon icon="lucide:info" />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent
                      side="bottom"
                      className="bg-black text-white max-w-[200px] text-center text-xs border-none z-[9999] rounded-sm py-2 px-3"
                    >
                      {t("fundraising.grossAmount")}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </p>
            </div>
            {isTargetVisible && (
              <div className="w-1/2 text-right">
                <p>
                  <span className="text-gray-600">{t("word.fTarget")}:</span>
                  <br />
                  <strong className="font-semibold">
                    {formatRp(targetFundraising || 0)}
                  </strong>
                </p>
              </div>
            )}
          </div>
          <Progress
            value={
              totalFundraising
                ? (totalFundraising / targetFundraising) * 100
                : 0
            }
            variant="striped"
            className="mb-3 h-2 bg-gray-200"
            classNameIndicator="bg-blue-500"
          />
        </>
      ) : (
        <>
          <Progress
            value={
              totalFundraising
                ? (totalFundraising / targetFundraising) * 100
                : 0
            }
            variant="striped"
            className={cn("h-2 bg-gray-200", isCardV2 ? "mb-1.5" : "mb-2.5")}
            classNameIndicator="bg-blue-500"
          />
          <div
            className={cn(
              "mb-0.5 flex justify-between",
              isCardV2 ? "text-xs" : "text-[13px]"
            )}
          >
            <span className="text-gray-600">{t("word.collected")}:</span>
            <span className="font-semibold">
              {formatRp(totalFundraising || 0)}
            </span>
          </div>
          {isTargetVisible && (
            <div
              className={cn(
                "mb-0.5 flex justify-between",
                isCardV2 ? "text-xs" : "text-[13px]"
              )}
            >
              <span className="text-gray-600">{t("word.fTarget")}:</span>
              <span className="font-semibold">
                {formatRp(targetFundraising || 0)}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DonationReceived;
