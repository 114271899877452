// hooks/useLocale.js
'use client';
import { useSearchParams } from 'next/navigation';
import { localeMap, DEFAULT_LOCALE } from "@/lib/utils"
import { Locale } from 'date-fns/locale';
type LocaleType = {
  id: Locale,
  en: Locale
}

export function useLocaleDate() {
  const searchParams = useSearchParams();
  const langParam = searchParams ? searchParams.get('lang') : null;
  
  const localeKey = langParam && localeMap[langParam as keyof LocaleType] ? langParam : DEFAULT_LOCALE;
  const locale = localeMap[localeKey as keyof LocaleType];
  
  return {
    localeKey,
    locale,
  };
}
